import clsx from "clsx";
import { ReactNode } from "react";


type Props = {
    items: Array<{
        label: string;
        value: number | string | undefined | null | ReactNode,
        strong?: boolean;
        hideIfNoValue?: boolean;
        italic?: boolean;
    }>
}
export default function DefinitionList({ items }: Props) {
    return (
        <dl className="relative space-y-2 text-sm [&>div]:flex [&>div]:justify-between [&>div]:gap-2 [&_dt]:text-text-primary [&_dd]:text-dark-blue">
            {items.map((item, i) => {
                if (item.hideIfNoValue && !item.value) {
                    return null
                }
                return (
                    <div key={i} className={clsx(item.italic && "italic")}>
                        <dt>
                            <Item strong={item.strong}>{item.label ?? '-'}</Item>
                        </dt>
                        <dd className="pl-4 truncate">
                            <Item strong={item.strong}>{item.value ?? '-'}</Item>
                        </dd>
                    </div>
                )

            })}
        </dl>
    )
}

function Item({ children, strong }: { children: ReactNode, strong?: boolean }) {
    if (strong) {
        return (
            <strong className="text-dark-blue">
                {children}
            </strong>
        )
    }
    return <>{children}</>
}

import { EnumItem, InterimFileResource, LinkResource, MediaResource, SimpleUserResource } from "./appTypes";
import { BillingDestinationResource } from "./paymentTypes";

export type TaxCaseResource = {
    id: string;
    createdAt: string;
    status: EnumItem;
    vehicle: VehicleResource | null;
    assessment: TaxCaseAssessment | null;
    links: {
        offer: LinkResource | null;
        terms: LinkResource | null;
        invoice: LinkResource | null;
        paymentAdvice: LinkResource | null;
        receipt: MediaResource | null;
    }
    possibleBillingDestinations: BillingDestinationResource[] | null;
    possiblePaymentMethods: EnumItem[]
    media: MediaResource[] | null
    note: string | null;
    importantNote: string | null;
    client: SimpleUserResource | null;
    owner: SimpleUserResource | null;
    flags: TaxCaseFlags | null;
}

export type TaxCaseFlags = {
    createdAt: string | null,
    preAssessedAt: string | null
    acceptedAt: string | null,
    paymentReceivedAt: string | null,
    documentsReceivedAt: string | null,
    invoiceStartedAt: string | null,
    invoiceDueAt: string | null,
    completedAt: string | null
}


export type VehicleResource = {
    link: string | null,
    brand: string | null,
    model: string | null,
    variant: string | null,
    vin: string | null,
    isNew: boolean | null,
    firstRegDate: string | null,
    mileage: number | null,
    color: string | null,
    horsePower: string | null,
    body: EnumItem | null,
    gearbox: EnumItem | null,
    fuel: EnumItem | null,
    image: LinkResource | null,
    type: EnumItem | null,
    quality: EnumItem | null
    useCase: EnumItem | null
    ccEngineSize: string | null,

    emissionUnit: EnumItem | null,
    emissionWltp: number | null,

    fuelEfficiency: number | null,
    emissionAmount: number | null,
    electricConsumption: number | null,
    electricRange: number | null,
    batteryCapacity: number | null
}

export type TaxCaseAssessment = {
    taxableValue: number,
    newPrice: number,
    tradePrice: number,
    estimatedRegistrationFee: number,
    estimatedCollateral: number;
    fee: number,
    numberPlates: number,
    total: number,
    totalNoVat: number,
    createdAt: string,
    expiresAt: string,
    message: null | string,
    projectedRegistrationFee: number | null;
    isAcceptable: boolean,
}

type CreateTaxCaseBase = {
    note: string,
    isPriority: boolean,
    evaluationTypeId: number,
    files: Array<{ uri: string, name: string }>,
    interimFiles: InterimFileResource[];
    paymentMethod?: number;
}
export type CreateTaxCaseByVin = CreateTaxCaseBase & {
    vehicle: {
        isNew?: boolean;
        useCaseId: number;
        vin: string;
        mileage: number | null;
        firstRegDate: string | null;
    }
}
export type CreateTaxCaseByLink = CreateTaxCaseBase & {
    vehicle: {
        link: string | null;
        isNew?: boolean;
        vin: string | null;
    }
}
export type CreateTaxCaseForm = CreateTaxCaseByVin & CreateTaxCaseByLink;

export const createEmptyTaxCreationInput = (): CreateTaxCaseForm => ({
    evaluationTypeId: 1,
    files: [],
    interimFiles: [],
    isPriority: false,
    note: "",
    paymentMethod: 1,
    vehicle: {
        firstRegDate: "",
        link: "",
        mileage: null,
        useCaseId: 1,
        vin: "",
        isNew: false
    },
})

import { TaxCaseResource } from "@/types/taxTypes";
import { formatDate, formatPrice } from "@/utils/string";
import { t } from "i18next";
import { useId } from "react";

type AssessmentProps = {
    assessment: TaxCaseResource['assessment'];
}
export default function Assessment({ assessment }: AssessmentProps) {
    const titleid = useId()

    return (
        <div className="bg-gray-50 rounded-lg p-6">
            <div className="flex items-center justify-between flex-wrap gap-4">
                <div className="">
                    <h3 className="font-semibold text-dark-blue" id={titleid}>{t("preassessment")}</h3>
                    <p className="text-xs leading-6">
                        {t("validUntil")} {formatDate(assessment?.expiresAt)}
                    </p>
                </div>
            </div>
            <dl className="text-sm mt-6 pt-4 border-t" aria-labelledby={titleid}>
                <div className="flex justify-between items-center mb-2">
                    <dt className="font-medium">{t("newPrice")}</dt>
                    <dd className="text-dark-blue font-semibold">{formatPrice(assessment?.newPrice, true)}</dd>
                </div>
                <div className="flex justify-between items-center">
                    <dt className="font-medium">{t("tradePrice")}</dt>
                    <dd className="text-dark-blue font-semibold">{formatPrice(assessment?.tradePrice, true)}</dd>
                </div>
                <div className="flex justify-between items-center pt-4 mt-4 mb-2 border-t">
                    <dt className="font-medium">{t("estimatedRegistrationFee")}</dt>
                    <dd className="text-dark-blue font-semibold">{formatPrice(assessment?.estimatedRegistrationFee, true)}</dd>
                </div>
                {assessment?.projectedRegistrationFee != null && (
                    <div className="flex justify-between items-center italic">
                        <dt className="font-medium">{t("estimatedRegistrationFee_2025")}</dt>
                        <dd className="text-dark-blue font-semibold">{formatPrice(assessment?.projectedRegistrationFee, true)}</dd>
                    </div>
                )}
            </dl>
        </div>
    )
}

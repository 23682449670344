import { Button, Field, Input, Label } from '@headlessui/react';
import { t } from 'i18next';
import { useRef } from 'react';
import ContentLoading from '../../components/ContentLoading';
import { Spinner } from '../../components/Spinner';
import { useDealerQuery, useMutateDealerContactData } from '../../hooks/useDealer';
import { ReactFormSubmit } from '../../types/helperTypes';


export default function UpdateDealerNotificationSettings() {
    const { data } = useDealerQuery()
    const { mutate, isPending: mutationPending, data: updatedDealer } = useMutateDealerContactData()
    const formRef = useRef<HTMLFormElement>(null)
    if (!data) {
        return <ContentLoading />
    }
    const dealer = data.data

    async function handleSubmit(e: ReactFormSubmit) {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        mutate({
            bookKeepingEmail: formData.get("bookKeepingEmail")?.toString() || dealer.bookKeepingEmail,
            escalationEmail: formData.get("escalationEmail")?.toString() || dealer.escalationEmail,
        })
    }

    return (

        <Spinner loading={mutationPending}>
            <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-10">
                <div className="px-4 sm:px-0 md:col-span-4">
                    <h2 className="text-lg font-semibold leading-7 text-gray-700">{t("emails")}</h2>
                </div>

                <form ref={formRef} className="md:col-span-6" onSubmit={handleSubmit}>
                    <div className="md:col-span-6 grid gap-4">

                        <Field>
                            <Label className="input-label">
                                {t("bookKeepingEmail")}
                            </Label>
                            <Input
                                type="email"
                                multiple={true}
                                name="bookKeepingEmail"
                                className="input mt-1"
                                defaultValue={updatedDealer?.data.bookKeepingEmail ?? dealer.bookKeepingEmail}
                            />
                        </Field>

                        <Field>
                            <Label className="input-label">
                                {t("escalationEmail")}
                            </Label>
                            <Input
                                type="email"
                                multiple={true}
                                name="escalationEmail"
                                className="input mt-1"
                                defaultValue={updatedDealer?.data.escalationEmail ?? dealer.escalationEmail}
                            />
                        </Field>
                        <div className='flex justify-end'>
                            <Button
                                type='submit'
                                className="btn btn-primary"
                            >
                                {t("save")}
                            </Button>

                        </div>
                    </div>
                </form>
            </div>

        </Spinner>

    )
}
